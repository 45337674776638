import React from 'react';

import '../assets/templates.css'
import '../assets/cdn-emoji-font.css'
import '../assets/colors.css'
import '../assets/globals.css'
import '../assets/fonts.css'

const Subtitle = ({ className, animationStyle, rows, emoji,configStyle,highLightStyle }) => {
  const texts = rows.map((row) =>
    row.widgets.map((widget) => widget.text).join(' ')
  );
  
  
  
  const widgetBr = rows.map((row) =>
  row.widgets.map((widget) => widget.break))

  console.log('widgetbr',widgetBr);
  
  const classes = rows.map((row) =>
    row.widgets.map((widget) => widget.className).join('')
  );

  console.log("aaa",texts);
  console.log("eeee",classes);  
  console.log("configs",configStyle);  
  console.log("high",highLightStyle); 


  const generateTextStyle = (
    configStyle
  )=> {
    return {
      fontFamily:configStyle.fontFamily,
      top:configStyle.top + "%",
      fontWeight: configStyle.fontWeight,
      fontSize: `${configStyle.fontSize}px`,
      color: configStyle.color,
      fontShadow:configStyle.fontShadow,
      stroke:configStyle.stroke,
      position: "absolute",
      textAlign: "center",
      width:"100%",
      strokeColor:configStyle.strokeColor,
      fontUpperCase:configStyle.fontUpperCase
    };
  };

  return (
    <div style={generateTextStyle(configStyle)} className={`${className} ${animationStyle}`}>
       <style>{`
        :root {
          --font-color: ${configStyle.color};
          --stroke-color: ${configStyle.strokeColor};
          --main-color: ${highLightStyle.mainColor};
          --second-color: ${highLightStyle.secondColor};
          --third-color: ${highLightStyle.thirdColor};
          --font-color: ${configStyle.fontColor};
        }
      `}</style>
      <div className = "row-container">
      {texts.map((text,index)=>(
        <>
        <div key={index} className={`row ${classes}`}>
          {text}
        </div>
        {/* {widgetBr ? <br  key={index} />:null} */}
        </>
      ))}
      <div>{emoji}</div>
      </div>
    </div>
  );
};

export default Subtitle;
