import { Video, Sequence, AbsoluteFill } from 'remotion';
import Subtitle from './Subtitle';
import '../assets/templates.css'
import '../assets/cdn-emoji-font.css'
import '../assets/colors.css'
import '../assets/globals.css'

const MainVideo = ({ subtitles }) => {
  
  return (
    <>
      <AbsoluteFill>
        <Video src={require('../assets/sample-video.mp4')} />
      </AbsoluteFill>

      {subtitles[0].json.inputProps.chunks.map((chunk) => {
        console.log(chunk);
        
        return (
          <Sequence key={chunk.start_caption} from={chunk.start_caption} durationInFrames={chunk.end_caption - chunk.start_caption}>
            <Subtitle 
              rows={chunk.rows}
              emoji={chunk.emoji}
              className={chunk.classes}
              animationStyle={chunk.row_animations}
              configStyle={subtitles[0].json.inputProps.config_style}
              highLightStyle={subtitles[0].json.inputProps.highlight_style}
            />
          </Sequence>
        );
      })}
    </>
  );
};

export default MainVideo;
