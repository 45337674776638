import { Composition } from 'remotion';
import MainVideo from './components/MainVideo';
const subtitles = require('./data/sample-input.json')

export const RemotionVideo: React.FC = () => {

const duration = subtitles[0].json.inputProps.durationInFrames
console.log(duration);
const width = subtitles[0].json.inputProps.width
console.log(width);
const height = subtitles[0].json.inputProps.height
console.log(height);


	return (
    <Composition
		id="MainVideo"
		component={MainVideo}
		durationInFrames={duration}
		fps={30}
		width={width}
		height={height}
		defaultProps={{
			subtitles:subtitles
		}}
    />
  );
	}